import { useIntl } from "react-intl"
import {
  GetTokenSummaryMarketsDataResponse,
  GetTokenSummaryResponse,
} from "../../../../client"
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Card } from "../../../components/Card"
import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import { Link } from "react-router-dom"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { trackTokenExchangeLinkClickedEvent } from "../../../utils/tokenUtils"
import { formatKaspaAmount, getTimeDifference } from "../../../utils/utils"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../../components/partials/Krc20PriceUnitToggleButton"

const TokenMarketList = ({
  tokenData,
  loading,
}: {
  tokenData?: GetTokenSummaryResponse
  loading?: boolean
}) => {
  const intl = useIntl()
  const marketsData = tokenData?.marketsData || []
  const { krc20PriceUnit, marketData: kasMarketData } = useGlobalData()

  const columns: ColumnsType<GetTokenSummaryMarketsDataResponse> = [
    {
      title: intl.formatMessage({ id: "EXCHANGE" }),
      render: (_, record: GetTokenSummaryMarketsDataResponse) => {
        const { metadata } = record

        return (
          <Link
            className="fw-bold d-flex align-items-center text-body text-hover-primary"
            to={metadata.url}
            target="_blank"
            onClick={() =>
              trackTokenExchangeLinkClickedEvent(
                tokenData?.ticker || "",
                metadata.name
              )
            }
          >
            <TokenIcon url={metadata.iconUrl} className="me-3" size="25px" />
            {metadata.name}
          </Link>
        )
      },
    },
    {
      title: intl.formatMessage({
        id:
          krc20PriceUnit === "USD"
            ? "LATEST_PRICE_IN_USD"
            : "LATEST_PRICE_IN_KAS",
      }),
      render: (_, record: GetTokenSummaryMarketsDataResponse) => {
        const { marketData } = record
        const price =
          krc20PriceUnit === "USD"
            ? `$${marketData.priceInUsd.toLocaleString(undefined, {
                maximumFractionDigits: 8,
              })}`
            : `${(
                marketData.priceInUsd / (kasMarketData?.price || 0)
              ).toLocaleString(undefined, {
                maximumFractionDigits: 8,
              })} KAS`

        return <div className="d-flex align-items-center">{price}</div>
      },
    },
    {
      title: intl.formatMessage({ id: "VOLUME_24H" }),
      render: (_, record: GetTokenSummaryMarketsDataResponse) => {
        const { marketData } = record

        return (
          <div className="d-flex align-items-center">
            $
            {marketData.volumeInUsd.toLocaleString(undefined, {
              maximumFractionDigits: 0,
            })}
          </div>
        )
      },
    },
    {
      title: intl.formatMessage({ id: "UPDATED" }),
      render: (_, record: GetTokenSummaryMarketsDataResponse) => {
        const { lastUpdated } = record

        return (
          <div className="d-flex align-items-center">
            {getTimeDifference(lastUpdated, false, "Recently")}
          </div>
        )
      },
    },
  ]

  return (
    <div className="col-xl-12">
      <Card
        className="pb-8"
        title={
          <div className="d-flex align-items-center gap-2">
            {intl.formatMessage(
              {
                id: "TOKEN.HEADER.MARKET_LIST",
              },
              {
                ticker: tokenData?.ticker,
              }
            )}
            <span>
              <Krc20PriceUnitToggleButton />
            </span>
          </div>
        }
        body={
          <Table
            size="middle"
            loading={loading}
            columns={columns}
            dataSource={marketsData}
            scroll={{ x: "max-content" }}
            pagination={false}
          />
        }
      />
    </div>
  )
}

export { TokenMarketList }
