/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react"
import { useIntl } from "react-intl"
import { GetTokenSummaryResponse, SwapApiService } from "../../../../client"
import { Drawer, Modal, notification, Skeleton } from "antd"
import { formatTokenAmountWithRoundDown } from "../../../utils/tokenUtils"
import { useKasware } from "../../../hooks/useKasware"
import { useLocalStorageState, useRequest } from "ahooks"
import BigNumber from "bignumber.js"
import { useSwapService } from "../services/SwapService"
import { SwapHistory } from "./SwapHistory"
import {
  getBalanceForToken,
  hasSufficientKasForGas,
  RESERVED_KAS_FOR_GAS,
  performSwap,
  isSwapDisabled,
} from "../utils"
import { SwapDetails } from "./SwapDetails"
import { SwapCardHeader } from "./SwapCardHeader"
import { KAS_DECIMAL, KASPA_ASSET_LOGO } from "../../../constants/constants"
import { SwapInputCard } from "./SwapInputCard"
import { SwapSettingsModalBody } from "./SwapSettingsModalBody"
import { ConnectWalletButton } from "./ConnectWalletButton"
import { kaspaToSompi } from "../../../utils/utils"
import { swapSlippageKey } from "../../../constants/localStorageKeys"
import { trackSwapOrderFailed, trackSwapOrderSubmitted } from "../analytics"

const MIN_RECEIVE_USD = 3
const DEFAULT_SLIPPAGE = 5

interface SwapCardProps {
  token?: GetTokenSummaryResponse
  loading?: boolean
}

export enum PendingStep {
  PAY = "pay",
  SIGN = "sign",
}

const SwapCard: React.FC<SwapCardProps> = ({ token, loading }) => {
  const intl = useIntl()
  const [api, contextHolder] = notification.useNotification()

  const [swapHistoryOpen, setSwapHistoryOpen] = useState(false)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const headerLoading = loading && !token
  const [isBuyMode, setIsBuyMode] = useState(true)
  const [amount, setAmount] = useState(0)
  const [slippage = DEFAULT_SLIPPAGE, setSlippage] =
    useLocalStorageState<number>(swapSlippageKey, {
      defaultValue: DEFAULT_SLIPPAGE,
      listenStorageChange: true,
    })
  const [swapProcessing, setSwapProcessing] = useState(false)
  const [pendingStep, setPendingStep] = useState<PendingStep | undefined>()

  const fromTicker = isBuyMode ? "KAS" : token?.ticker || ""
  const toTicker = isBuyMode ? token?.ticker || "" : "KAS"
  const fromIconUrl = isBuyMode ? KASPA_ASSET_LOGO : token?.iconUrl
  const toIconUrl = isBuyMode ? token?.iconUrl : KASPA_ASSET_LOGO

  const {
    address,
    balance,
    krc20Balances,
    connected,
    sendKaspa,
    signKRC20Transaction,
    publicKey,
    signMessage,
    refreshData,
  } = useKasware()

  const currentBalance = isBuyMode
    ? BigNumber(balance?.total || 0)
    : getBalanceForToken(krc20Balances, fromTicker)

  const fromDecimal = isBuyMode ? KAS_DECIMAL : token?.decimal

  const formattedBalance = formatTokenAmountWithRoundDown(
    "",
    currentBalance.toNumber() || 0,
    fromDecimal!,
    2
  )

  const onAmountChange = (value: string) => {
    if (/^\d*\.?\d{0,8}$/.test(value)) {
      setAmount(value === "" ? 0 : Number(value))
    }
  }

  const setMaxAmount = () => {
    if (isBuyMode) {
      const balanceSubGas = currentBalance.minus(
        kaspaToSompi(RESERVED_KAS_FOR_GAS)
      )
      setAmount(
        balanceSubGas.gt(0)
          ? balanceSubGas.div(10 ** fromDecimal!).toNumber()
          : 0
      )
    } else {
      setAmount(currentBalance.div(10 ** fromDecimal!).toNumber())
    }
  }

  const { data: quoteResponse, loading: quoteLoading } = useRequest(
    async () => {
      if (!amount || amount <= 0 || !fromTicker || !toTicker || !slippage)
        return

      return SwapApiService.swapControllerGetSwapQuote(
        slippage.toString(),
        amount.toString(),
        toTicker,
        fromTicker
      )
    },
    {
      refreshDeps: [amount, fromTicker, toTicker, slippage],
      pollingInterval: 10 * 1e3,
      pollingWhenHidden: false,
      debounceWait: 0.5 * 1e3,
    }
  )

  const toDecimal = quoteResponse?.quote?.chainDecimal
  const expectedReceiveAmount = BigNumber(
    quoteResponse?.quote?.expectedReceiveAmount || 0
  ).div(10 ** toDecimal!)
  const expectedReceiveUsd = BigNumber(quoteResponse?.quote?.outAmountUsd || 0)

  const { updateSwap } = useSwapService()

  const performSwapHandler = async () => {
    try {
      if (!quoteResponse) {
        api.error({
          message: intl.formatMessage({ id: "SWAP_FAILED" }),
          description: intl.formatMessage({ id: "QUOTE_NOT_AVAILABLE" }),
          placement: "top",
        })
        return
      }

      if (!sendKaspa || !signKRC20Transaction || !signMessage) {
        api.error({
          message: intl.formatMessage({ id: "SWAP_FAILED" }),
          description: intl.formatMessage({
            id: "WALLET_FUNCTIONS_NOT_AVAILABLE",
          }),
          placement: "top",
        })
        return
      }

      setSwapProcessing(true)

      const result = await performSwap({
        intl,
        quoteResponse,
        amount,
        slippage,
        address,
        publicKey,
        sendKaspa,
        signKRC20Transaction,
        signMessage,
        updateSwap,
        setPendingStep,
      })

      if (result.success) {
        api.success({
          message: intl.formatMessage(
            { id: "SWAP_ORDER_SUBMITTED" },
            { toTicker }
          ),
          description: intl.formatMessage({
            id: "SWAP_ORDER_SUBMITTED_DESCRIPTION",
          }),
          placement: "top",
          showProgress: true,
          pauseOnHover: true,
        })

        trackSwapOrderSubmitted({ token: token?.ticker || "" })
      } else {
        setSwapHistoryOpen(true)
        api.error({
          message: intl.formatMessage({ id: "SWAP_FAILED" }),
          description: result.error,
          placement: "top",
        })

        trackSwapOrderFailed({ token: token?.ticker || "" })
      }
    } catch (e: any) {
      api.error({
        message: intl.formatMessage({ id: "SWAP_FAILED" }),
        description: e.message,
        placement: "top",
      })

      trackSwapOrderFailed({ token: token?.ticker || "" })
    } finally {
      setPendingStep(undefined)
      setSwapProcessing(false)
    }
  }

  const PerformSwapButton = () => {
    const swapDisabled = isSwapDisabled()
    if (swapDisabled) {
      return (
        <button
          type="button"
          className={`btn w-100 py-4 btn-secondary`}
          disabled={true}
        >
          {intl.formatMessage({ id: "SWAP_DISABLED" })}
        </button>
      )
    }

    let message = intl.formatMessage({ id: "SWAP" })
    let disabled = false

    const invalidReceiveAmount = expectedReceiveAmount.lte(0)
    const invalidReceiveUsd = expectedReceiveUsd.lt(MIN_RECEIVE_USD)

    const amountBase = BigNumber(amount).multipliedBy(10 ** fromDecimal!)
    const insufficientBalance = currentBalance.lt(amountBase)

    const kasBalance = BigNumber(balance?.total || 0)
    const insufficientKasForGas = !hasSufficientKasForGas(
      kasBalance,
      fromTicker === "KAS" ? amount : 0
    )

    if (
      quoteLoading ||
      swapProcessing ||
      invalidReceiveAmount ||
      invalidReceiveUsd ||
      insufficientBalance ||
      insufficientKasForGas
    ) {
      disabled = true
    }

    if (pendingStep) {
      const enumToText = {
        [PendingStep.PAY]: intl.formatMessage({ id: "WAITING_FOR_PAYMENT" }),
        [PendingStep.SIGN]: intl.formatMessage({ id: "WAITING_FOR_SIGNATURE" }),
      }
      message = enumToText[pendingStep]
    } else if (amount === 0) {
      message = intl.formatMessage({ id: "ENTER_AN_AMOUNT" })
    } else if (insufficientBalance) {
      message = intl.formatMessage(
        { id: "INSUFFICIENT_BALANCE" },
        { fromTicker }
      )
    } else if (insufficientKasForGas) {
      message = intl.formatMessage(
        { id: "MIN_KAS_FOR_GAS" },
        { amount: RESERVED_KAS_FOR_GAS }
      )
    } else if (quoteLoading) {
      message = intl.formatMessage({ id: "GETTING_QUOTE" })
    } else if (invalidReceiveAmount || invalidReceiveUsd) {
      message = intl.formatMessage(
        { id: "SWAP_AMOUNT_TOO_LOW" },
        { min: MIN_RECEIVE_USD }
      )
    }

    return (
      <button
        type="button"
        className={`btn w-100 py-4 btn-success`}
        onClick={performSwapHandler}
        disabled={disabled}
      >
        {message}
      </button>
    )
  }

  const SwapCardContent = () => (
    <>
      {contextHolder}
      <Drawer
        title={intl.formatMessage({ id: "SWAP_HISTORY" })}
        onClose={() => setSwapHistoryOpen(false)}
        open={swapHistoryOpen}
        placement="bottom"
        destroyOnClose
        className="bg-body"
        height={"60%"}
      >
        <SwapHistory />
      </Drawer>

      <Modal
        title={
          <div className="fw-bold fs-1">
            {intl.formatMessage({ id: "SETTINGS" })}
          </div>
        }
        open={settingsOpen}
        onCancel={() => setSettingsOpen(false)}
        footer={null}
        width={600}
      >
        <SwapSettingsModalBody slippage={slippage} setSlippage={setSlippage} />
      </Modal>

      <SwapCardHeader
        ticker={token?.ticker}
        onHistoryClick={() => setSwapHistoryOpen(true)}
        onSettingsClick={() => setSettingsOpen(true)}
      />

      <SwapInputCard
        type="from"
        amount={amount}
        tokenTicker={fromTicker}
        tokenIconUrl={fromIconUrl || ""}
        tokenPriceUSD={token?.price?.priceInUsd || 0}
        onAmountChange={onAmountChange}
        balance={formattedBalance}
        onMaxClick={setMaxAmount}
      />

      <div className="position-relative" style={{ zIndex: 1 }}>
        <div
          className="position-absolute start-50 translate-middle-x"
          style={{ top: "-20px" }}
        >
          <div
            className="btn btn-light border border-body border-2 d-flex align-items-center justify-content-center"
            onClick={() => {
              // Switch the receive amount to the input amount
              if (expectedReceiveAmount.gt(0)) {
                const newFromDecimal = isBuyMode ? token?.decimal : KAS_DECIMAL
                const newFromAmount = expectedReceiveAmount.toFixed(
                  newFromDecimal!,
                  BigNumber.ROUND_DOWN
                )
                setAmount(BigNumber(newFromAmount).toNumber())
              } else {
                setAmount(0)
              }

              setIsBuyMode(!isBuyMode)
              refreshData()
            }}
          >
            <i className="fa-solid fa-arrows-rotate fs-6 d-flex ms-1 text-primary fw-bold py-2"></i>
          </div>
        </div>
      </div>

      <div className="mt-1">
        <SwapInputCard
          type="to"
          amount={expectedReceiveAmount}
          outAmountUsd={expectedReceiveUsd.toNumber()}
          tokenTicker={toTicker}
          tokenIconUrl={toIconUrl || ""}
          tokenPriceUSD={token?.price?.priceInUsd || 0}
        />
      </div>

      {connected ? (
        <div className="mt-4 mb-3">
          <PerformSwapButton />
        </div>
      ) : (
        <div className="mt-4 mb-3">
          <ConnectWalletButton />
        </div>
      )}

      <SwapDetails
        quoteResponse={quoteResponse}
        token={token}
        fromDecimal={fromDecimal!}
        toDecimal={toDecimal!}
        isBuyMode={isBuyMode}
        slippage={slippage}
      />
    </>
  )

  return <div>{headerLoading ? <Skeleton active /> : SwapCardContent()}</div>
}

export { SwapCard }
