import moment from "moment"

interface AreaTooltipProps {
  price: number
  timestamp: number
  isDarkMode: boolean
  unit: string
  style: React.CSSProperties
}

const AreaTooltip = ({
  price,
  timestamp,
  isDarkMode,
  unit,
  style,
}: AreaTooltipProps) => (
  <div
    style={{
      position: "absolute",
      padding: "8px",
      backgroundColor: isDarkMode ? "#1E1E2D" : "white",
      border: `1px solid ${isDarkMode ? "#2B2B43" : "#e0e0e0"}`,
      borderRadius: "4px",
      color: isDarkMode ? "#92929F" : "#333",
      fontSize: "12px",
      pointerEvents: "none",
      zIndex: 1000,
      width: "160px",
      ...style,
    }}
  >
    <div className="">
      {moment(timestamp * 1000).format("YYYY-MM-DD HH:mm")}
    </div>
    <div>
      <span className="text-muted">Price:</span>{" "}
      <span>
        {price.toFixed(8)} {unit}
      </span>
    </div>
  </div>
)

export { AreaTooltip }
export type { AreaTooltipProps }
