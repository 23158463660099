import { useIntl } from "react-intl"
import { useRequest, useSize } from "ahooks"
import { TokenService } from "../../../client"
import { PopluarCard } from "./components/PopluarCard"
import { TopGainerCard } from "./components/TopGainerCard"
import { TopActivityCard } from "./components/TopActivityCard"
import { Carousel } from "antd"
import { useRef } from "react"

const cardClass = "col-xl-3 col-sm-6 mb-6"

const MOBILE_BREAKPOINT = 576
export const TrendingCardsSection = () => {
  const ref = useRef(null)
  const size = useSize(ref)
  const isMobile = (size?.width || MOBILE_BREAKPOINT + 1) < MOBILE_BREAKPOINT

  const intl = useIntl()
  const { data, loading } = useRequest(async () =>
    TokenService.tokenControllerGetKrc20Trending()
  )

  const Cards = [
    <div className={cardClass}>
      <PopluarCard tokens={data?.mostPopular} loading={loading} />
    </div>,
    <div className={cardClass}>
      <TopGainerCard tokens={data?.topGainer} loading={loading} />
    </div>,
    <div className={cardClass}>
      <TopActivityCard
        tokens={data?.mostMinted}
        title={intl.formatMessage({
          id: "TOP_ACTIVITY_CARD.TOP_MINTED",
        })}
        unit={intl.formatMessage({
          id: "TOP_ACTIVITY_CARD.TOP_MINTED.UNIT",
        })}
        loading={loading}
      />
    </div>,
    <div className={cardClass}>
      <TopActivityCard
        tokens={data?.mostTransferred}
        title={intl.formatMessage({
          id: "TOP_ACTIVITY_CARD.TOP_TRANSFERRED",
        })}
        unit={intl.formatMessage({
          id: "TOP_ACTIVITY_CARD.TOP_TRANSFERRED.UNIT",
        })}
        loading={loading}
      />
    </div>,
  ]

  return (
    <div ref={ref}>
      {isMobile ? (
        <Carousel arrows>{Cards}</Carousel>
      ) : (
        <div className="row">{Cards}</div>
      )}
    </div>
  )
}
