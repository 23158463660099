/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react"
import { TokenIcon } from "../../../components/partials/TokenIcon"
import { Link } from "react-router-dom"
import { getTokenRoute } from "../../../hooks/navigator"
import { GetTokenSummaryResponse } from "../../../../client"
import { TOKEN_ASSET_HOST } from "../../../constants/constants"
import { PriceDiffBadge } from "../../../components/PriceDiffBadge"
import { useIntl } from "react-intl"
import { Skeleton } from "antd"

type Props = {
  className?: string
  tokens?: GetTokenSummaryResponse[]
  loading?: boolean
}

const MARGIN_BOTTOM_SIZE = "6"

const Row = ({
  ticker,
  rank,
  rightText,
}: {
  ticker: string
  rank?: number
  rightText?: React.ReactNode
}) => {
  return (
    <div className={`d-flex align-items-center mb-${MARGIN_BOTTOM_SIZE}`}>
      {rank && (
        <span className="fs-5 fw-semibold text-gray-600 me-3">{rank}</span>
      )}
      <TokenIcon
        url={`${TOKEN_ASSET_HOST}/icons/${ticker?.toUpperCase()}.jpg`}
        className="me-3"
        size="25px"
      />
      <div className="flex-grow-1">
        <Link
          to={getTokenRoute(ticker)}
          className="text-gray-800 text-hover-primary fw-bold fs-6"
        >
          {ticker}
        </Link>
      </div>
      {rightText}
    </div>
  )
}

export const PopluarCard: React.FC<Props> = ({
  className = "",
  tokens,
  loading,
}) => {
  const intl = useIntl()
  const rows = useMemo(() => {
    return tokens?.map((token, index) => (
      <Row
        ticker={token?.ticker}
        rank={index + 1}
        rightText={
          <PriceDiffBadge diff={token.price?.change24h} precision={0} />
        }
      />
    ))
  }, [tokens])

  return (
    <div className={`card ${className} h-300px`}>
      <div className="card-header border-0">
        <h3 className="card-title fw-bold text-dark">
          <i className="bi bi-fire me-2 fs-4 text-muted"></i>
          {intl.formatMessage({
            id: "POPULAR_CARD.TITLE",
          })}
        </h3>
      </div>
      <div className={`card-body pt-2 mb-n${MARGIN_BOTTOM_SIZE}`}>
        {loading ? <Skeleton active /> : rows}
      </div>
    </div>
  )
}
