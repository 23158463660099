import { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import {
  createChart,
  CandlestickData,
  ISeriesApi,
  SeriesType,
  Time,
} from "lightweight-charts"
import { Card } from "../../../components/Card"
import { ChartIntervalSegment } from "./ChartIntervalSegment"
import { TokenCandle } from "../../../../client"
import { useThemeMode } from "../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider"
import { ChartTooltip } from "./ChartTooltip"
import { AreaTooltip } from "./AreaTooltip"
import { useGlobalData } from "../../../utils/GlobalDataProvider"
import { Krc20PriceUnitToggleButton } from "../../../components/partials/Krc20PriceUnitToggleButton"
import { Spin, Button, Space } from "antd"
import { BarChartOutlined, LineChartOutlined } from "@ant-design/icons"
import moment from "moment"
import { ChartInterval } from "../Token"

type ChartType = "candlestick" | "area"

const TokenPriceChart = ({
  candles = [],
  chartInterval,
  onIntervalChange,
  loading,
  ticker,
}: {
  candles?: TokenCandle[]
  chartInterval: ChartInterval
  onIntervalChange: (interval: ChartInterval) => void
  loading?: boolean
  ticker: string
}) => {
  const intl = useIntl()
  const chartContainerRef = useRef<HTMLDivElement>(null)
  const { mode } = useThemeMode()
  const isDarkMode = mode === "dark"
  const [tooltipData, setTooltipData] = useState<CandlestickData | null>(null)
  const [areaTooltipData, setAreaTooltipData] = useState<{
    price: number
    timestamp: number
    x: number
    y: number
  } | null>(null)
  const [showTooltip, setShowTooltip] = useState(false)
  const { krc20PriceUnit } = useGlobalData()
  const [chartType, setChartType] = useState<ChartType>("area")

  useEffect(() => {
    if (!chartContainerRef.current) return

    const chart = createChart(chartContainerRef.current, {
      layout: {
        background: { color: isDarkMode ? "#151521" : "#ffffff" },
        textColor: isDarkMode ? "#92929F" : "#333",
      },
      grid: {
        vertLines: { color: isDarkMode ? "#2B2B43" : "#f0f0f0" },
        horzLines: { color: isDarkMode ? "#2B2B43" : "#f0f0f0" },
      },
      width: chartContainerRef.current.clientWidth,
      height: 500,
      timeScale: {
        timeVisible: true,
        secondsVisible: false,
        tickMarkFormatter: (time: number) => {
          const format = chartInterval === "1d" ? "HH:mm" : "MM/DD"
          return moment(time * 1000).format(format)
        },
        rightOffset: 5,
        barSpacing: 20,
        fixLeftEdge: true,
        fixRightEdge: true,
        lockVisibleTimeRangeOnResize: true,
      },
      crosshair: {
        vertLine: {
          visible: true,
          labelVisible: false,
        },
        horzLine: {
          visible: true,
          labelVisible: true,
        },
      },
    })

    // Format the data for the series
    const formattedData = candles
      .map((item) => {
        const baseData = {
          time: moment(item.timestamp).unix() as unknown as Time,
          open: Number(krc20PriceUnit === "KAS" ? item.open_kas : item.open),
          high: Number(krc20PriceUnit === "KAS" ? item.high_kas : item.high),
          low: Number(krc20PriceUnit === "KAS" ? item.low_kas : item.low),
          close: Number(krc20PriceUnit === "KAS" ? item.close_kas : item.close),
        }
        return chartType === "area"
          ? { time: baseData.time, value: baseData.close }
          : baseData
      })
      .sort(
        (a, b) => (a.time as unknown as number) - (b.time as unknown as number)
      )

    let series: ISeriesApi<SeriesType>

    if (chartType === "candlestick") {
      series = chart.addCandlestickSeries({
        upColor: "#26a69a",
        downColor: "#ef5350",
        borderVisible: false,
        wickUpColor: "#26a69a",
        wickDownColor: "#ef5350",
        priceFormat: {
          type: "price",
          precision: 8,
          minMove: 0.00000001,
        },
      })
    } else {
      series = chart.addAreaSeries({
        lineColor: "#3addbe",
        topColor: "#3addbe",
        bottomColor: "rgba(58, 221, 190, 0.05)",
        priceFormat: {
          type: "price",
          precision: 8,
          minMove: 0.00000001,
        },
      })
    }

    if (formattedData.length > 0) {
      series.setData(formattedData)
    }

    chart.subscribeCrosshairMove((param) => {
      if (
        param.time === undefined ||
        !param.point ||
        param.point.x < 0 ||
        param.point.x > chartContainerRef.current!.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainerRef.current!.clientHeight
      ) {
        setShowTooltip(false)
        setAreaTooltipData(null)
        return
      }

      const data = param.seriesData.get(series) as any
      if (!data) {
        setShowTooltip(false)
        setAreaTooltipData(null)
        return
      }

      if (chartType === "candlestick") {
        setTooltipData(data)
        setShowTooltip(true)
      } else {
        setAreaTooltipData({
          price: data.value,
          timestamp: data.time as number,
          x: param.point.x,
          y: param.point.y,
        })
      }
    })

    // Fit the chart to the data
    chart.timeScale().fitContent()

    // Cleanup
    return () => {
      chart.remove()
    }
  }, [chartInterval, isDarkMode, krc20PriceUnit, candles, chartType])

  return (
    <div className="col-xl-12">
      <Card
        title={
          <div className="d-flex align-items-center gap-2">
            {intl.formatMessage({
              id: "TOKEN.HEADER.PRICE_CHART",
            })}
            <span className="text-muted">
              ({ticker} / {krc20PriceUnit})
            </span>
            <span>
              <Krc20PriceUnitToggleButton />
            </span>
          </div>
        }
        toolbar={
          <Space>
            <Button.Group>
              <Button
                type={chartType === "area" ? "primary" : "default"}
                icon={<LineChartOutlined />}
                onClick={() => setChartType("area")}
              />
              <Button
                type={chartType === "candlestick" ? "primary" : "default"}
                icon={<BarChartOutlined />}
                onClick={() => setChartType("candlestick")}
              />
            </Button.Group>
            <ChartIntervalSegment
              chartInterval={chartInterval}
              onIntervalChange={onIntervalChange}
            />
          </Space>
        }
        body={
          <Spin spinning={loading} size="large">
            <div ref={chartContainerRef} style={{ position: "relative" }}>
              {showTooltip && tooltipData && chartType === "candlestick" && (
                <ChartTooltip
                  open={tooltipData.open}
                  high={tooltipData.high}
                  low={tooltipData.low}
                  close={tooltipData.close}
                  change={
                    ((tooltipData.close - tooltipData.open) /
                      tooltipData.open) *
                    100
                  }
                  timestamp={Number(tooltipData.time)}
                  isDarkMode={isDarkMode}
                  unit={krc20PriceUnit || ""}
                />
              )}
              {areaTooltipData && chartType === "area" && (
                <AreaTooltip
                  price={areaTooltipData.price}
                  timestamp={areaTooltipData.timestamp}
                  isDarkMode={isDarkMode}
                  unit={krc20PriceUnit || ""}
                  style={{
                    left: areaTooltipData.x - 12,
                    top: areaTooltipData.y,
                    transform: "translate(-100%, -50%)",
                  }}
                />
              )}
            </div>
          </Spin>
        }
      />
    </div>
  )
}

export { TokenPriceChart }
